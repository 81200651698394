import React, { useContext, useState } from "react";
import * as S from "./VendaOrderPageStyle";
import { useLoad } from "../../../context/LoadContext";
import { helpers } from "../../../Helpers/helpers";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function VendaOrderPage({ setLinkPay, openPay, dado, onClose }) {
    const { startLoading, stopLoadingDelay } = useLoad();
    const { clientData } = useContext(AuthContext);
    const { addToMyBuyOrders, myBuyOrders } = useContext(AuthContext);

    if (!dado)
        return null;

    const handleGeneratePix = async () => {
        startLoading();

        var hihi = false;

        myBuyOrders && myBuyOrders.length > 0 && myBuyOrders.forEach(c => {
            if (c.purchaseId === dado.purchaseId) {
                hihi = true;
            }
        })

        if (hihi){
            stopLoadingDelay();
            return alert("Você Já tem uma ordem de compra ativa para esse contrato.");
        }

        await helpers.generateBuyOrderAsync(dado.id, clientData.id).then(res => {
            if (res) {
                addToMyBuyOrders(res);
                onClose();
                openPay();
                setLinkPay(res.ticketPayment)
                return alert("Ordem De Compra Gerada Com Sucesso.")
            } else {
                return alert("Erro Ao Gerar Pix.");
            }
        }).catch(err => {
            console.log(err);
        })
        stopLoadingDelay();
    }


    return (
        <>
            <S.ContainerModal>
                <div className="exit" onClick={onClose}>
                    Voltar
                </div>
                <span className="titulo">ORDEM DE VENDA</span>

                <S.Infos>
                    <S.InfoContainer style={{ gridTemplateColumns: "1fr 1fr 3fr 3fr" }}>
                        <div className="item">
                            <span className="fieldName">ID</span>
                            <div className="box">
                                {dado.id}
                            </div>
                        </div>
                        <div className="item">
                            <span className="fieldName">ID Contrato</span>
                            <div className="box">
                                {dado.purchaseId}
                            </div>
                        </div>
                        <div className="item">
                            <span className="fieldName">Valor de Venda</span>
                            <div className="box">
                                {dado.totalPrice}
                            </div>
                        </div>
                        <div className="item">
                            <span className="fieldName">Valor Produto</span>
                            <div className="box">
                                {dado.contractPrice}
                            </div>
                        </div>
                    </S.InfoContainer>

                    <S.InfoContainer style={{ gridTemplateColumns: "2fr 2fr 2fr" }}>
                        <div className="item">
                            <span className="fieldName">Valor a Lucrar</span>
                            <div className="box">
                                {dado.finalIncome}
                            </div>
                        </div>
                        <div className="item">
                            <span className="fieldName">Data Da Ordem</span>
                            <div className="box">
                                {dado.dateCreated ? helpers.formatDateToBrazilianFormat(dado.dateCreated) : "Indefinida"}
                            </div>
                        </div>
                        <div className="item">
                            <span className="fieldName">Final do Contrato</span>
                            <div className="box">
                                {helpers.formatDateToBrazilianFormat(dado.endContractDate)}
                            </div>
                        </div>
                    </S.InfoContainer>
                </S.Infos>

                <S.GenerateBuyOrder>
                    <span className="nome">Comprar Agora!</span>
                    <button onClick={handleGeneratePix} className="generatePix">GERAR PIX DE PAGAMENTO</button>
                </S.GenerateBuyOrder>

            </S.ContainerModal>
        </>
    )
}