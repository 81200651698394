import React, { useState, useEffect, useContext } from "react";
import * as S from "./ExchangeDashboardStyle";
import { useLoad } from "../../context/LoadContext";
import { helpers } from "../../Helpers/helpers";
import { useNavigate } from "react-router-dom";
import VendaOrderPage from "./VendaOrderPage/VendaOrderPage";
import { AuthContext } from "../../context/AuthContext";

export default function ExchangeDashboard() {

    const [filtered, setFiltered] = useState([]);
    const [inputFilter, setInputFilter] = useState("");
    const [menuOptionsVisibleFor, setMenuOptionsVisibleFor] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [databaseVendas, setDatabaseVendas] = useState([]);
    const { startLoading, stopLoadingDelay } = useLoad();
    const { clientData } = useContext(AuthContext);
    const [modalPay, setModalPay] = useState(false);
    const [linkPay, setLinkPay] = useState("");

    useEffect(() => {
        startLoading();
        helpers.obterOrdensDeVenda().then(res => {
            if (res) {
                setDatabaseVendas(res.filter(c => c.sellerId != clientData.id));
                setFiltered(res.filter(c => c.sellerId != clientData.id && c.status === 1))
            }
        }).catch(err => {
            console.log(err);
        })
        stopLoadingDelay();
    }, [])


    const handleChange = (e) => {
        if (e.trim() === "") {
            setInputFilter(e);
        }
        else {
            setInputFilter(e);
        }
    }

    useEffect(() => {
        if (inputFilter.trim() === "") {
            setFiltered(databaseVendas);
        }
        else {
            setFiltered(databaseVendas.filter(t => t.id.toLowerCase().trim().includes(inputFilter.toLowerCase().trim())));
        }
    }, [inputFilter])

    return (
        <>
            {modalPay && (
                <S.ModalPay>
                    <div className="boxCentral">
                        <span className="exit" onClick={() => setModalPay(false)}>X</span>
                        <span className="titulo">Conclua A Compra Agora</span>
                        <button onClick={() => window.open(linkPay)} className="botao">Pagar Pix</button>
                        <span className="aviso">Caso opte por pagar depois, acesse Minhas Ordens De Compra</span>
                    </div>
                </S.ModalPay>
            )}

            <VendaOrderPage setLinkPay={setLinkPay} openPay={() => setModalPay(true)} dado={selectedOrder} onClose={() => setSelectedOrder(null)} />
            <S.Dashboard>
                {/* <S.ContainerInicial>
                    <button onClick={() => navigate("/createsellorder")} style={{ backgroundColor: "rgba(0, 150, 220, 1)", color: "white" }}>Criar Ordem De Venda</button>
                    <button onClick={() => navigate("/mysellorders")} style={{ backgroundColor: "rgba(0, 0, 250, 1)", color: "white" }}>Minhas Ordens De Venda</button>
                    <button onClick={() => navigate("/mybuyorders")} style={{ backgroundColor: "rgba(100, 220, 0, 1)", color: "white" }}>Minhas Ordens De Compra</button>
                </S.ContainerInicial> */}

                <span className="title"> Exchange Dashboard </span>
                <span className="subtitle"> Ordens de Vendas Disponíveis </span>

                <S.FiltrosContainer>
                    <S.Filtro>
                        <span className="filterName">ID Ordem</span>
                        <input onChange={(e) => handleChange(e.target.value)} className="inputSelectFilter" placeholder="Digite Aqui..." />
                    </S.Filtro>
                    <S.Filtro>
                        <span className="filterName">Data de Criação</span>
                        <select className="inputSelectFilter">
                            <option>Todos</option>
                            <option>Ontém</option>
                            <option>À 1 Semana</option>
                            <option>À 2 Semanas</option>
                            <option>1 Mês</option>
                        </select>
                    </S.Filtro>
                    <S.Filtro>
                        <span className="filterName">Valor Mínimo</span>
                        <input type="number" className="inputSelectFilter" placeholder="Digite Aqui..." />
                    </S.Filtro>
                    <S.Filtro>
                        <span className="filterName">Valor Máximo</span>
                        <input type="number" className="inputSelectFilter" placeholder="Digite Aqui..." />
                    </S.Filtro>
                </S.FiltrosContainer>

                <S.TableFiltered>
                    <S.TableContainer>
                        <div className="nomeColunas">
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID Contrato</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Data Criação</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor de Venda</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor Produto</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valori. Restante</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Tempo Restante</span>
                            <span className="item"></span>
                        </div>

                        <div className="rows">
                            {filtered && filtered.map((dado) => (
                                <div className="row" key={dado.id}>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{dado.id}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{(dado.purchaseId)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.formatDateToBrazilianFormat(dado.dateCreated)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.totalPrice || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.contractPrice || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.finalIncome || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.formatDateToBrazilianFormat(dado.endContractDate) || ""}</span>
                                    <div className="item">
                                        <img
                                            onMouseEnter={() => setMenuOptionsVisibleFor(dado.id)}
                                            src="./menu-vertical-icon.png"
                                            alt="Menu Icon"
                                        />

                                        {menuOptionsVisibleFor === dado.id && (
                                            <div
                                                onMouseLeave={() => setMenuOptionsVisibleFor(null)}
                                                className="menu"
                                                onClick={() => setSelectedOrder(dado)}
                                            >
                                                <span className="name">Ver Mais</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </S.TableContainer>
                </S.TableFiltered>
            </S.Dashboard>
        </>
    )
}