import React, { useState, useEffect, useContext } from "react";
import * as S from "./ContractsStyle";
import ContractPage from "./ContractPage/ContractPage";
import { AuthContext } from "../../context/AuthContext";
import { helpers } from "../../Helpers/helpers";
// import TabelaSimulacao from "../Tabelas/TabelaSimulacao"

export default function Contracts() {
    const { modeloDeContratos } = useContext(AuthContext)

    const [pageContractShow, setPageContractShow] = useState(null);

    const onClose = () => {
        setPageContractShow(null);
    }

    const [modeloSelecionadoSimulacao, setModeloSelecionadoSimulacao] = useState(null);
    const [quantidadeSimulacao, setQuantidadeSimulacao] = useState(1); 
    const [investimento, setInvestimento] = useState(0);
    const [duracao, setDuracao] = useState(0);
    const [rentabilidade, setRentabilidade] = useState(0);
    const [lucro, setLucro] = useState(0);
    const [montanteFinal, setMontanteFinal] = useState(0);



    const handleCreateSimulation = () => {
        if (modeloSelecionadoSimulacao) {
            const investimentoCalculado = quantidadeSimulacao * modeloSelecionadoSimulacao.value;
            setInvestimento(investimentoCalculado);
            setDuracao(modeloSelecionadoSimulacao.duration);
            setRentabilidade(modeloSelecionadoSimulacao.gain * 100);
            const lucroCalculado = investimentoCalculado * modeloSelecionadoSimulacao.gain;
            setLucro(lucroCalculado);
            setMontanteFinal(investimentoCalculado + lucroCalculado);
        }
    };

    return (
        <>
            {pageContractShow && (
                <ContractPage onClose={onClose} contract={pageContractShow} />
            )}

            <S.ContratosContainer>
                <S.Title>VEJA NOSSOS PRODUTOS</S.Title>


                <S.ProductsContainerArea style={{ marginTop: "100px" }}>
                    <h2>Produtos mais vendidos 🔥</h2>

                    <S.ProductsList>
                        {modeloDeContratos && modeloDeContratos.map((model, index) => (
                            <>
                                <S.Card>
                                    <p className="title">{model.title} 📄</p>

                                    <div className="productImage">
                                        <img src={model.imageUrl && model.imageUrl.trim() != "" ? model.imageUrl : "introducer.jpeg.webp"} />
                                    </div>

                                    <ul className="infoContract">
                                        <li className="info">Lucro de {model.gain*100}%</li>
                                        <li className="info">Saque liberado após {model.firstWithdraw} dias</li>
                                        <li className="info">Duração de {model.duration} meses</li>
                                    </ul>

                                    <div className="valorUnitario">
                                        <span className="valor">R${helpers.formatToBrazilianCurrency(model.value || 0)}</span>
                                    </div>

                                    <button className="selecionarBtn" onClick={() => setPageContractShow({
                                        uniValue: model.value || 0,
                                        daysToFirstWithdraw: model.firstWithdraw,
                                        duration: model.duration,
                                        finalIncome: model.gain*100,
                                        productName: model.title,
                                        daysToFirstWithdraw: model.firstWithdraw,
                                        imageUrl: model.imageUrl
                                    })}>Comprar</button>
                                </S.Card>
                            </>
                        ))}
                    </S.ProductsList>

                    {/* <S.SimulacaoContainer>
                        <span className="title">SIMULE SEUS GANHOS</span>
                        <S.Simulacao>
                            <div className="boxes">
                                <div className="itemBox">
                                    <select
                                        value={modeloSelecionadoSimulacao ? modeloSelecionadoSimulacao.title : "Selecione"}
                                        onChange={(e) => {
                                            const selectedModel = modeloDeContratos.find(model => model.title === e.target.value);
                                            setModeloSelecionadoSimulacao(selectedModel);
                                        }}
                                    >
                                        <option value="">Selecione o modelo</option>
                                        {modeloDeContratos && modeloDeContratos.map((  model, index) => (
                                            <option key={index} value={model.title}>{model.title}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="itemBox">
                                    <input
                                        type="number"
                                        min={1}
                                        placeholder="Quantidade"
                                        value={quantidadeSimulacao}
                                        onChange={(e) => setQuantidadeSimulacao(e.target.value)}
                                    />
                                </div>
                                <button onClick={handleCreateSimulation} className="simulate">SIMULAR</button>
                                <button onClick={() => {
                                    setInvestimento(0);
                                    setDuracao(0);
                                    setRentabilidade(0);
                                    setLucro(0);
                                    setMontanteFinal(0);
                                    setModeloSelecionadoSimulacao(null);
                                    setQuantidadeSimulacao(1);
                                }} className="clean">LIMPAR</button>
                            </div>
                        </S.Simulacao>
                    </S.SimulacaoContainer> */}

                    {/* <S.ResultadoSimulacao>
                        <TabelaSimulacao 
                            resultadoSimulacao={{
                                investimento,
                                duracao,
                                rentabilidade,
                                lucro,
                                montanteFinal,
                            }} 
                        />
                    </S.ResultadoSimulacao> */}
                </S.ProductsContainerArea>
            </S.ContratosContainer>
        </>
    )
}