import styled from "styled-components";

export const Dashboard = styled.div`
    width: 100%;
    font-size: 42px;
    font-weight: 800;
    color: #000026;
    transition: .5s;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-shadow: 4px 4px 2px rgba(0,0,0,0.2);
    min-height: 90vh;

    .title{
        width: 100%;
        text-align: center;
        color: white;
    }   
        
    .subtitle{
        width: 100%;
        text-align: center;
        color: rgba(255, 255, 255, 0.6);
        font-size: 22px
    }    
`

export const ContainerInicial = styled.div`
    width: 100%;
    margin-bottom: 150px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 20px;

    button{
        width: 100%;
        height: 60px;
        border-radius: 6px;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        transition: .3s;
        box-shadow: 4px 4px 1px rgba(0,0,0,0.4);
        cursor: pointer;
        text-shadow: 2px 2px 1px rgba(0,0,0,0.4);

        &:hover{
            transform: scale(0.98);
            box-shadow: 7px 7px 1px rgba(0,0,0,0.4);
        }
    }
`;

export const FiltrosContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr;
    margin-top: 40px;
    gap: 20px;
`

export const Filtro = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .filterName{
        font-size: 22px;
        color: white;
    }

    .inputSelectFilter{
        width: 100%;
        height: 40px;
        border-radius: 6px;
        box-sizing: border-box;
        text-align: center;
        font-size: 16px;
        border: 0;
    }
`

export const TableFiltered = styled.div`
    max-height: 500px;
    overflow-y: auto;
    margin-top: 40px;
    width: 100%;
    box-sizing: border-box;
`;

export const TableContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
    // background: linear-gradient(-75deg, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
    // border-radius: 12px 12px 0 0;
    border: 2px solid rgba(100, 100, 100, 1);
    box-sizing: border-box;

    .nomeColunas{
        width: 100%;
        min-height: 45px;
        height: 45px;
        border-bottom: 2px solid rgba(100, 100, 100, 1);
        border-left: 2px solid rgba(100, 100, 100, 1);
        border-right: 2px solid rgba(100, 100, 100, 1);
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 2fr 2fr 4fr 3fr 3fr 3fr 3fr 1fr;
        box-sizing: border-box;
        background: rgba(20, 20, 20, 1);

        .item{
            font-size: 16px;
            color: black;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-sizing: border-box;
            color: rgba(200, 160, 0, 1);
        }
    }

    .rows{
        width: 100%;
        display: flex;
        flex-direction: column;

        .row{
            width: 100%;
            min-height: 45px;
            height: 45px;
            border: 2px solid rgba(100, 100, 100, 1);;
            border-top: 0;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: 2fr 2fr 4fr 3fr 3fr 3fr 3fr 1fr;
            box-sizing: border-box;
        }


        .item{
            font-size: 16px;
            height: 100%;
            width: 100%;
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            box-sizing: border-box;
            transition: .3s;
            position: relative;

            img{
                width: 30px;
                opacity: 0.6;
                transition: .3s;
                cursor: pointer;
                cursor: pointer;
            }

            .menu{
                position: absolute;
                top: 0;
                right: 0;
                background: linear-gradient(-45deg, rgba(220, 220, 220, 1), rgba(250, 250, 250, 1), rgba(220, 220, 220, 1));
                width: max-content;
                padding: 5px 20px;
                border: 2px solid black;
                border-radius: 12px;
                display: flex;
                flex-direction: column;

                cursor: pointer;

                .name{
                    font-size: 16px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: black;
                    text-align: center;
                    box-sizing: border-box;
                    width: 100%;
                }
            }
        }
    }
`;


export const ModalPay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .boxCentral{
        // width: 400px;
        // height: 400px;
        padding: 40px 50px;
        border-radius: 12px;
        background: linear-gradient(-45deg, rgba(20, 20, 20, 1), rgba(50, 50, 50, 1), rgba(20, 20, 20, 1));
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 5px;
        border: 3px solid black;
        position: relative;

        .exit{
            font-size: 16px;
            color: red;
            position: absolute;
            top: 15px;
            left: 15px;
            cursor: pointer;
        }

        .titulo{
            font-size: 26px;
            text-align: center;
            color: white;
        }

        .botao{
            width: 250px;
            height: 35px;
            border-radius: 6px;
            background: rgba(80, 200, 0, 1);
            border: 0;
            box-shadow: 4px 4px 1px rgba(0,0,0,0.4);
            font-size: 18px;
            cursor: pointer;
            transition: .3s;

            &:hover{
                box-shadow: 8px 8px 1px rgba(0,0,0,0.6);
                background: rgba(0, 200, 200, 1);
            }
        }

        .aviso{
            font-size: 12px;
            color: rgba(200, 200, 0, 1);
            text-align: center;
        }
    }
`;

// export const nome = styled.div``

// export const nome = styled.div``

// export const nome = styled.div``

// export const nome = styled.div``

// export const nome = styled.div``

