import styled from "styled-components";

export const CreateSellOrderContainer = styled.div`
    width: 100%;
    font-weight: 800;
    color: #000026;
    transition: .5s;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    text-shadow: 4px 4px 2px rgba(0,0,0,0.2);
    min-height: 90vh;

    .title{
        width: 100%;
        text-align: center;
        color: white;
        font-size: 42px;
    }    
`;

export const MyContractsContainer = styled.div`
    position: relative;
    width: 100%;

    .arrow{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        transition: .3s;

        &:hover{
            width: 55px;
        }
    }

    .top{
        top: 30px;
    }

    .bottom{
        bottom: -50px;
    }

    .selecione{
        color: rgba(255, 255, 255, 0.7);
    }
`;

export const MyContracts = styled.div`
    width: 100%;
    max-height: 380px;
    // overflow: hidden;
    margin-top: 80px;
    gap: 20px;
    display: flex;
    flex-direction: column;

    .contract{
        width: 100%;
        height: 180px;
        background: rgba(0,0,0,0.6);
        color: white;
        padding: 20px;
        box-sizing: border-box; 
        display: grid;
        grid-template-columns: 1fr 3fr 3fr 3fr 3fr;
        transition: .3s;

        &:hover{
            transform: scale(1.05);
        }

        .boxCenter{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;

            .titleField{
                font-size: 26px;
                color: white;
            }

            .fieldValue{
                font-size: 22px;
                color: rgba(20, 220, 0, 1);
            }
        }

        .id{
            font-size: 28px;
            color: rgba(100, 220, 0, 1);
        }
    }
`;

export const Passo2Venda = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 100px;

    .title{
        color: white;
        font-size: 32px;
    }

    .askBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        gap: 10px;

        .titleAsk{
            font-size: 22px;
            color: white;
        }

        .inputText{
            width: 350px;
            height: 45px;
            border: 0;
            border-radius: 6px;
            box-sizing: border-box;
            text-align: center;
            font-size: 18px;
        }

        .buttonConfirmValue{
            width: 350px;
            height: 45px;
            border: 0;
            border-radius: 6px;
            box-sizing: border-box;
            cursor: pointer;
            transition: .3s;
            text-align: center;
            font-size: 18px;
            background: linear-gradient(to right, rgba(0, 200, 0, 1), rgba(100, 220, 0, 1));

            &:hover{
                transform: scale(0.97);
            }
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;

// export const nome = styled.div``;
