import styled from "styled-components";

export const ContainerModal = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background: linear-gradient(-45deg, rgba(210, 210, 210, 1), rgba(255, 255, 255, 1), rgba(210, 210, 210, 1));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    padding: 50px 40px 30px 40px;

    .titulo{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 38px;
    }

    .exit{
        position: fixed;
        z-index: 999;
        top: 40px;
        left: 40px;
        cursor: pointer;
        transition: .3s;
        font-size: 20px;

        &:hover{
            font-size: 18px;
        }
    }
`;  

export const Infos = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
`;

export const InfoContainer = styled.div`
    width: 100%;
    display: grid;
    gap: 20px;

    .item{
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 2px;

        .fieldName{
            text-align: center;
            font-size: 20px;
        }

        .box{
            width: 100%;
            height: 40px;
            border-radius: 6px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(0,0,0,0.4);
            text-align: center;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
`;

export const GenerateBuyOrder = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 40px;

    .nome{
        text-align: center;
        font-size: 26px;
    }

    .generatePix{
        width: 100%;
        height: 45px;
        border-radius: 6px;
        background: linear-gradient(to right, rgba(0, 200, 0, 1), rgba(50, 200, 0, 1));
        border: 2px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 0;
        cursor: pointer;
        font-size: 16px;
        color: white;
        text-shadow: 1px 1px 1px rgba(0,0,0,0.4);
        transition: .3s;

        &:hover{
            text-shadow: 2px 2px 1px rgba(0,0,0,0.4);
        }
    }
`;

// export const nome = styled.div``;

// export const nome = styled.div``;
