import React, { useState, useEffect, useContext } from "react";
import * as S from "./CarteiraStyle";
import TransactionTable from "../Tabelas/WithDrawnsTable";
import { AuthContext } from "../../context/AuthContext";
import { helpers } from "../../Helpers/helpers";
import ModalDeSaque from "./ModalDeSaque/ModalDeSaque";
import ModalDeInvestir from "./ModalDeInvestir/ModalDeInvestir";
import axios from "axios";
import { useLoad } from "../../context/LoadContext";

export default function Carteira() {

    const { clientData, daysToWithdraw, monthsToWithdraw, token } = useContext(AuthContext);
    const { startLoading, stopLoadingDelay } = useLoad();
    const [showModalSaque, setShowModalSaque] = useState(false);
    const [showModalInvestir, setShowModalReinvestir] = useState(false);
    const [mToWithdraw, setMToWithdraw] = useState("inválido");
    const [dToWithdraw, setDToWithdraw] = useState("inválido");
    const [diaDeSaque, setDiaDeSaque] = useState(false);

    const onClose = () => {
        setShowModalSaque(false);
    }

    const onOpen = () => {
        setShowModalSaque(true);
    }

    const onOpenInvestir = () => {
        setShowModalReinvestir(true);
    }

    const onCloseInvestir = () => {
        setShowModalReinvestir(false);
    }

    useEffect(() => {
        if (mToWithdraw === "inválido" || dToWithdraw === "inválido") {
            startLoading()
            obterDados();
        } else {
            stopLoadingDelay()
        }
    }, []);

    if (mToWithdraw === "inválido" || dToWithdraw == "inválido") {
        startLoading()
    } else {
        stopLoadingDelay()
    }

    const obterDados = async () => {
        startLoading()
        const response1 = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}systemconfig/withdrawn_date_months`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        const response2 = await axios.get(`${process.env.REACT_APP_BASE_ROUTE}systemconfig/withdrawn_date_days`, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (await response1.data) {
            if (response1.data.value)
                setMToWithdraw(response1.data.value);
        } else return;

        if (await response2.data) {
            if (response2.data.value)
                setDToWithdraw(response2.data.value);
        } else return;

        const today = new Date();
        const options = {
            timeZone: 'America/Sao_Paulo',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        const brDate = new Intl.DateTimeFormat('pt-BR', options).format(today);
        var dia = helpers.handle0FirstPosition(brDate.split("/")[0]);
        var mes = helpers.handle0FirstPosition(brDate.split("/")[1]);

        if (verificarDiaEMes(dia, mes, response2.data.value, response1.data.value)) {
            setDiaDeSaque(true);
        }
        stopLoadingDelay();

    }

    const verificarDiaEMes = (dia, mes, dToWithdraw, mToWithdraw) => {
        const verificacaoDoDia = dToWithdraw.split("-").includes(dia);
        const verificacaoDoMes = mToWithdraw.split("-").includes(mes);
        return verificacaoDoDia && verificacaoDoMes;
    }


    const handleArrayMonths = () => {
        var stringMeses = mToWithdraw || "";
        return helpers.sortArray(stringMeses.split("-"))
            .map(month => helpers.handleMonthNumber(month))
            .join(", ");
    };



    return (
        <>
            {clientData ? (
                <>
                    {showModalSaque && (
                        <ModalDeSaque onClose={onClose} />
                    )}

                    {showModalInvestir && (
                        <ModalDeInvestir onClose={onCloseInvestir} />
                    )}

                    <S.CarteiraContainer>
                        <S.RowOne>
                            <S.RowOneItem>
                                <S.Cartao>
                                    <img className="logotipo-cartao" alt="logotipo teste" src="logotipo-teste.png" />
                                    <div className="cartao-circle"><div className="circle-intern"></div></div>

                                    <S.CardHolder>
                                        <p className="cardHolderName">{clientData && clientData.name.toUpperCase()}</p>
                                        <p className="cardHolderNum">{helpers.generateCreditCardNumber(clientData.cpf, clientData.dateCreated)}</p>
                                        <div className="securityInfo">
                                            <div className="inf">
                                                <span className="lab">DATA DE CRIAÇÃO</span>
                                                <span className="labR">{helpers.formatDateToBrazilianFormat(clientData.dateCreated)}</span>
                                            </div>
                                            <div className="inf">
                                                <span className="lab">CÓD. DE SEGUR.</span>
                                                <span className="labR">275</span>
                                            </div>
                                        </div>
                                    </S.CardHolder>
                                </S.Cartao>

                                {diaDeSaque ? (
                                    <>
                                        <S.GenerateCard onClick={onOpen}>REALIZAR SAQUE</S.GenerateCard>
                                        <S.GenerateCard2 onClick={onOpenInvestir}>REINVESTIR LUCROS</S.GenerateCard2>
                                    </>
                                ) : (
                                    <>
                                        <S.GenerateCard style={{opacity: "0.6"}} onClick={() => {alert("Hoje Não É Dia De Saque.")}}>REALIZAR SAQUE</S.GenerateCard>
                                        <S.GenerateCard2 style={{opacity: "0.6"}} onClick={() => {alert("Hoje Não É Dia De Saque.")}}>REINVESTIR LUCROS</S.GenerateCard2>
                                    </>
                                )}
                            </S.RowOneItem>

                            <S.RowOneItem>
                                <S.TableStrange>
                                    <S.TitleItem> VALOR NA CARTEIRA </S.TitleItem>
                                    <S.ItemsRow>
                                        <div className="item">
                                            <div className="itemTitle">VALOR INTEIRO</div>
                                            <div className="itemVals">
                                                <div className="inf">U${helpers.formatToBrazilianCurrency((clientData.balance) / 5.34)}</div>
                                                <div className="inf">R${helpers.formatToBrazilianCurrency(clientData.balance)}</div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="itemTitle">VALOR DISPONÍVEL</div>
                                            <div className="itemVals">
                                                <div className="inf">U${helpers.formatToBrazilianCurrency(clientData.amountAvailableToWithdraw / 5.34)}</div>
                                                <div className="inf">R${helpers.formatToBrazilianCurrency(clientData.amountAvailableToWithdraw)}</div>
                                            </div>
                                        </div>
                                    </S.ItemsRow>
                                    <S.TitleItem> TOTAL JÁ OBTIDO </S.TitleItem>
                                    <S.ItemsRow>
                                        <div className="item">
                                            <div className="itemTitle">VALOR INTEIRO</div>
                                            <div className="itemVals">
                                                <div className="inf">U${helpers.formatToBrazilianCurrency(clientData.lucroTotalObtido / 5.34)}</div>
                                                <div className="inf">R${helpers.formatToBrazilianCurrency(clientData.lucroTotalObtido)}</div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="itemTitle">VALOR SACADO</div>
                                            <div className="itemVals">
                                                <div className="inf">U${helpers.formatToBrazilianCurrency(clientData.totalJaSacado / 5.34)}</div>
                                                <div className="inf">R${helpers.formatToBrazilianCurrency(clientData.totalJaSacado)}</div>
                                            </div>
                                        </div>
                                    </S.ItemsRow>
                                </S.TableStrange>

                            </S.RowOneItem>
                        </S.RowOne>

                        <S.DaysToWithdraw>
                            OS SAQUES OCORREM NOS DIAS {helpers.sortArray(dToWithdraw.split("-")).filter(s => s).join(", ")} DOS MESES DE {handleArrayMonths()}
                        </S.DaysToWithdraw>
                        <S.RowTwo>
                            <TransactionTable />
                        </S.RowTwo>


                    </S.CarteiraContainer>
                </>
            ) : (
                <></>
            )}

        </>
    )
}