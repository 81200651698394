import React, { useState, useEffect, useContext } from "react";
import * as S from "./MySellOrdersStyle";
import { useLoad } from "../../context/LoadContext";
import { helpers } from "../../Helpers/helpers";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function MySellOrders() {
    const { mySellOrders } = useContext(AuthContext);
    const [filtered, setFiltered] = useState([]);
    const [inputFilter, setInputFilter] = useState("");
    const [menuOptionsVisibleFor, setMenuOptionsVisibleFor] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const { startLoading, stopLoadingDelay } = useLoad();
    const navigate = useNavigate();

    useEffect(() => {
        startLoading();
        setFiltered(mySellOrders)
        stopLoadingDelay();
    }, [])


    const handleChange = (e) => {
        if (e.trim() === "") {
            setInputFilter(e);
        }
        else {
            setInputFilter(e);
        }
    }

    useEffect(() => {
        if (inputFilter.trim() === "") {
            setFiltered(mySellOrders);
        }
        else {
            setFiltered(mySellOrders.filter(t => t.id.toLowerCase().trim().includes(inputFilter.toLowerCase().trim())));
        }
    }, [inputFilter])

    return (
        <>
            <S.Dashboard>

                <span className="title"> Minhas Ordens De Venda </span>

                <S.FiltrosContainer>
                    <S.Filtro>
                        <span className="filterName">ID Ordem</span>
                        <input onChange={(e) => handleChange(e.target.value)} className="inputSelectFilter" placeholder="Digite Aqui..." />
                    </S.Filtro>
                    <S.Filtro>
                        <span className="filterName">Data de Criação</span>
                        <select className="inputSelectFilter">
                            <option>Todos</option>
                            <option>Ontém</option>
                            <option>À 1 Semana</option>
                            <option>À 2 Semanas</option>
                            <option>1 Mês</option>
                        </select>
                    </S.Filtro>

                </S.FiltrosContainer>

                <S.TableFiltered>
                    <S.TableContainer>
                        <div className="nomeColunas">
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID Contrato</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Data Criação</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor de Venda</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor Produto</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valori. Restante</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Data de Finalização</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Status</span>
                            <span className="item"></span>
                        </div>

                        <div className="rows">
                            {filtered && filtered.map((dado) => (
                                <div className="row" key={dado.id}>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{dado.id}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{dado.purchaseId}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.formatDateToBrazilianFormat(dado.dateCreated)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.totalPrice || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.contractPrice || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.finalIncome || 0)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.formatDateToBrazilianFormat(dado.endContractDate) || ""}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.handleVendaStatus(dado.status) || ""}</span>
                                    <div className="item">
                                        <img
                                            onMouseEnter={() => setMenuOptionsVisibleFor(dado.id)}
                                            src="./menu-vertical-icon.png"
                                            alt="Menu Icon"
                                        />

                                        {menuOptionsVisibleFor === dado.id && dado.status === 1 && (
                                            <div
                                                onMouseLeave={() => setMenuOptionsVisibleFor(null)}
                                                className="menu"
                                                onClick={() => setSelectedOrder(dado)}
                                            >
                                                <span className="name">Cancelar</span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </S.TableContainer>
                </S.TableFiltered>
            </S.Dashboard>
        </>
    )
}