import React, { useContext, useEffect, useState } from "react";
import * as S from "./CreateSellOrderStyle";
import TransactionsTable from "../Tabelas/ContractsTable";
import { AuthContext } from "../../context/AuthContext";
import { helpers } from "../../Helpers/helpers";
import axios from "axios";
import { useLoad } from "../../context/LoadContext";
import { useNavigate } from "react-router-dom";


export default function CreateSellOrder() {
    const [actualIndexes, setActualIndexes] = useState([0, 1]);
    const { clientData, addToMySellOrders, token } = useContext(AuthContext)
    const [purchases, setPurchases] = useState([])
    const [selected, setSelected] = useState(null);
    const [totalSellPrice, setTotalSellPrice] = useState("");
    const { startLoading, stopLoadingDelay } = useLoad();
    const navigate = useNavigate();

    useEffect(() => {
        if (clientData) {
            console.log(clientData.walletExtract.purchases)
            if (clientData.walletExtract.purchases && clientData.walletExtract.purchases.filter(e => (e.status === 2 && (!e.firstOwner || e.firstOwner.trim() === "" || e.firstOwner.trim() === clientData.id ))).length > 0) {
                setPurchases(clientData.walletExtract.purchases.filter(e => (e.status === 2 && (!e.firstOwner || e.firstOwner.trim() === "" || e.firstOwner.trim() === clientData.id ))))
            }
        }
    }, [])

    const handleChangeActual = (direction) => {
        if (purchases.length - 1 > 0) {
            const [firstIndex, secondIndex] = actualIndexes;

            if (direction === "top") {
                const newFirstIndex = (firstIndex - 1 + purchases.length) % purchases.length;
                const newSecondIndex = (secondIndex - 1 + purchases.length) % purchases.length;
                setActualIndexes([newFirstIndex, newSecondIndex]);
            } else if (direction === "bottom") {
                const newFirstIndex = (firstIndex + 1) % purchases.length;
                const newSecondIndex = (secondIndex + 1) % purchases.length;
                setActualIndexes([newFirstIndex, newSecondIndex]);
            }
        }
    };

    const handleSelect = (item) => {
        if (selected && selected.purchaseId) {
            if (item && selected.purchaseId === item.id)
                setSelected(null);
            else setSelected(item)
        } else setSelected(item);
    }

    const handleCreate = async () => {
        startLoading();
        if (selected && totalSellPrice.trim() !== "" && parseFloat(totalSellPrice.trim()) > 0) {
            await axios.post(`${process.env.REACT_APP_BASE_ROUTE}venda`, {
                sellerId: clientData.id,
                totalPrice: parseFloat(totalSellPrice),
                unityPrice: 0,
                purchaseId: selected.purchaseId,
                contractPrice: selected.totalPrice
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then(res => {
                if (res) {
                    console.log(res.data);
                    addToMySellOrders(res.data);
                    setSelected(null);
                    setTotalSellPrice(0);
                    stopLoadingDelay();
                    return alert("Ordem De Venda Criada Com Sucesso.");
                }
            }).catch(err => {
                console.log(err)
                stopLoadingDelay()
                return alert("Erro Ao Criar Ordem De Compra.");
            })
        } else {
            stopLoadingDelay()
            return alert("Contrato não selecionado ou valor de venda errado.");
        }
    }

    return (
        <>
            <S.CreateSellOrderContainer>
                <span className="title">Criar Ordem De Venda</span>
                <S.MyContractsContainer>
                    <span className="selecione">Selecione Um Contrato</span>
                    <S.MyContracts>
                        {purchases[actualIndexes[0]] && purchases[actualIndexes[0]].length - 1 != 0 && (
                            <>
                                <img onClick={() => handleChangeActual("top")} className="top arrow" src="./top-arrow-icon.png" />
                                <div onClick={() => handleSelect(purchases[actualIndexes[0]])} style={{ border: (purchases[actualIndexes[0]] && selected && selected.purchaseId === purchases[actualIndexes[0]].purchaseId) ? "3px solid rgba(100, 220, 0, 1)" : "" }} className="contract">
                                    <div className="boxCenter id">
                                        {purchases[actualIndexes[0]] && purchases[actualIndexes[0]].purchaseId}
                                    </div>
                                    <div className="valorContrato boxCenter">
                                        <span className="titleField">Valor Contrato</span>
                                        <span className="fieldValue">R${helpers.formatToBrazilianCurrency(purchases[actualIndexes[0]] && purchases[actualIndexes[0]].totalPrice)}</span>
                                    </div>
                                    <div className="valorContrato boxCenter">
                                        <span className="titleField">Valorização Restante</span>
                                        <span className="fieldValue">R${helpers.formatToBrazilianCurrency(purchases[actualIndexes[0]] && (purchases[actualIndexes[0]].finalIncome - purchases[actualIndexes[0]].currentIncome))}</span>
                                    </div>
                                    <div className="valorContrato boxCenter">
                                        <span className="titleField">Data Da Compra</span>
                                        <span className="fieldValue">{helpers.formatDateToBrazilianFormat(purchases[actualIndexes[0]] && purchases[actualIndexes[0]].purchaseDate)}</span>
                                    </div>
                                    <div className="valorContrato boxCenter">
                                        <span className="titleField">Data De Finalização</span>
                                        <span className="fieldValue">{helpers.formatDateToBrazilianFormat(purchases[actualIndexes[0]] && purchases[actualIndexes[0]].endContractDate)}</span>
                                    </div>
                                </div>

                                {purchases.length - 1 > 0 && (
                                    <div onClick={() => handleSelect(purchases[actualIndexes[1]])} style={{ border: (purchases[actualIndexes[1]] && selected && selected.purchaseId === purchases[actualIndexes[1]].purchaseId) ? "3px solid rgba(100, 220, 0, 1)" : "" }} className="contract">
                                        <div className="boxCenter id">
                                            {purchases[actualIndexes[1]] && purchases[actualIndexes[1]].purchaseId}
                                        </div>
                                        <div className="valorContrato boxCenter">
                                            <span className="titleField">Valor Contrato</span>
                                            <span className="fieldValue">R${helpers.formatToBrazilianCurrency(purchases[actualIndexes[1]] && purchases[actualIndexes[1]].totalPrice)}</span>
                                        </div>
                                        <div className="valorContrato boxCenter">
                                            <span className="titleField">Valorização Restante</span>
                                            <span className="fieldValue">R${helpers.formatToBrazilianCurrency(purchases[actualIndexes[1]] && (purchases[actualIndexes[1]].finalIncome - purchases[actualIndexes[1]].currentIncome))}</span>
                                        </div>
                                        <div className="valorContrato boxCenter">
                                            <span className="titleField">Data Da Compra</span>
                                            <span className="fieldValue">{helpers.formatDateToBrazilianFormat(purchases[actualIndexes[1]] && purchases[actualIndexes[1]].purchaseDate)}</span>
                                        </div>
                                        <div className="valorContrato boxCenter">
                                            <span className="titleField">Data De Finalização</span>
                                            <span className="fieldValue">{helpers.formatDateToBrazilianFormat(purchases[actualIndexes[1]] && purchases[actualIndexes[1]].endContractDate)}</span>
                                        </div>
                                    </div>
                                )}
                                <img onClick={() => handleChangeActual("bottom")} className="bottom arrow" src="./bottom-arrow-icon.png" />
                            </>
                        )}
                    </S.MyContracts>
                </S.MyContractsContainer>

                {selected && (
                    <>
                        <S.Passo2Venda>

                            <span className="title">Prossiga Com A Venda #{selected && selected.purchaseId}</span>

                            <div className="askBox">
                                <span className="titleAsk">Por Quanto Deseja Vender O Contrato?</span>
                                <input value={totalSellPrice} onChange={(e) => setTotalSellPrice(e.target.value)} className="inputText" type="number" placeholder="0,00" />
                                {totalSellPrice && totalSellPrice.trim() != "" && parseFloat(totalSellPrice.trim()) > 0 && (
                                    <>
                                        <button onClick={handleCreate} className="buttonConfirmValue">Prosseguir</button>
                                    </>
                                )}
                            </div>
                        </S.Passo2Venda>
                    </>
                )}
            </S.CreateSellOrderContainer>
        </>
    );
}