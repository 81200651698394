import React, { useState, useEffect, useContext } from "react";
import * as S from "./MyBuyOrdersStyle";
import { useLoad } from "../../context/LoadContext";
import { helpers } from "../../Helpers/helpers";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

export default function MyBuyOrders() {
    const { myBuyOrders } = useContext(AuthContext);
    const [filtered, setFiltered] = useState([]);
    const [inputFilter, setInputFilter] = useState("");
    const [menuOptionsVisibleFor, setMenuOptionsVisibleFor] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const { startLoading, stopLoadingDelay } = useLoad();
    const navigate = useNavigate();

    useEffect(() => {
        startLoading();
        setFiltered(myBuyOrders)
        stopLoadingDelay();
    }, [])


    const handleChange = (e) => {
        if (e.trim() === "") {
            setInputFilter(e);
        }
        else {
            setInputFilter(e);
        }
    }

    useEffect(() => {
        if (inputFilter.trim() === "") {
            setFiltered(myBuyOrders);
        }
        else {
            setFiltered(myBuyOrders.filter(t => t.id.toLowerCase().trim().includes(inputFilter.toLowerCase().trim())));
        }
    }, [inputFilter])

    return (
        <>
            <S.Dashboard>

                <span className="title"> Minhas Ordens De Compra </span>

                <S.FiltrosContainer>
                    <S.Filtro>
                        <span className="filterName">ID Ordem</span>
                        <input onChange={(e) => handleChange(e.target.value)} className="inputSelectFilter" placeholder="Digite Aqui..." />
                    </S.Filtro>

                </S.FiltrosContainer>

                <S.TableFiltered>
                    <S.TableContainer>
                        <div className="nomeColunas">
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>ID Venda</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Data Criação</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor A Pagar</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Valor Do Contrato</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Lucro Restante Do Contrato</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Pagamento</span>
                            <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>Opções</span>
                            <span className="item"></span>
                        </div>

                        <div className="rows">
                            {filtered && filtered.map((dado) => (
                                <div className="row" key={dado.id}>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{dado.id}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{dado.vendaId}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.formatDateToBrazilianFormat(dado.dateCreated)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.totalPrice)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.contractPrice)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>R${helpers.formatToBrazilianCurrency(dado.finalIncome)}</span>
                                    <span className="item" style={{ borderRight: "2px solid rgba(100, 100, 100, 1)" }}>{helpers.handlePagamentoTicketStatus(dado.status)}</span>
                                    <div className="item">
                                        {dado.status === 1 && (
                                            <button onClick={() => { window.open(dado.ticketPayment, '_blank') }}>Pagar</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </S.TableContainer>
                </S.TableFiltered>
            </S.Dashboard>
        </>
    )
}