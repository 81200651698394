import React, { useEffect, useState } from "react";
import * as S from "./NavbarStyle";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { useLoad } from "../../context/LoadContext";

export default function Navbar({ setShowProfile, showProfile }) {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [navegacao, setNavegacao] = useState("");
  const { startLoading, stopLoadingDelay } = useLoad();

  const navMap = {
    wallet: "Carteira",
    dashboard: "Dashboard",
    contracts: "Contratos",
    indication: "Indicação",
    exchange: "Exchange Dashboard",
    createsellorder: "Criar Ordem De Venda",
    mysellorders: "Minhas Ordens De Venda",
    mybuyorders: "Minhas Ordens De Compra",
    help: "Ajuda",
  };

  const handleChange = (e) => {
    startLoading()
    const targetValue = e.target.value;
    navigate(targetValue);
    setNavegacao(targetValue);
    stopLoadingDelay()
  };

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');
    const currentPath = pathArray[pathArray.length - 1]; // último elemento

    setNavegacao(navMap[currentPath] || currentPath);
  }, []);

  return (
    <S.NavContainer>
      <S.Navbar>
        <S.NavPartOne onClick={logout}>
          <img alt="exit icon" src="exit-door-icon.png" />
        </S.NavPartOne>

        <S.NavPartTwo>
          <S.PerfilIcon>
            <img onClick={() => { setShowProfile(!showProfile) }} alt="profile picture" src="profile-icon.png" />
          </S.PerfilIcon>
          <select onChange={(e) => handleChange(e)}>
            <option value="/dashboard" selected={navegacao === "Dashboard"}>Dashboard</option>
            <option value="/wallet" selected={navegacao === "Carteira"}>Carteira</option>
            <option value="/contracts" selected={navegacao === "Contratos"}>Contratos</option>
            {/* <option value="/chat" selected={navegacao === "Chat"}>Chat</option> */}
            <option value="/indication" selected={navegacao === "Indicações"}>Indicações</option>
            <option value="/exchange" selected={navegacao === "Exchange"}>Exchange</option>
            <option value="/createsellorder" selected={navegacao === "Criar Ordem De Venda"}>Criar Ordem De Venda</option>
            <option value="/mysellorders" selected={navegacao === "Minhas Ordens De Venda"}>Minhas Ordens De Venda</option>
            <option value="/mybuyorders" selected={navegacao === "Minhas Ordens De Compra"}>Minhas Ordens De Compra</option>
            <option value="/help" selected={navegacao === "Ajuda"}>Ajuda</option>
          </select>
        </S.NavPartTwo>
      </S.Navbar>
    </S.NavContainer>
  );
}